import { decorate, observable, computed } from "mobx";
import axios from "axios";

const instance = axios.create({
  baseURL: "https://alumni-api.joincoded.com"
  // baseURL: "http://127.0.0.1:9000"
});

class GraduateStore {
  graduates = [];
  graduate = null;
  locations = [];
  locationsLoading = true;
  graduatesLoading = true;

  fetchAllGraduates = async () => {
    try {
      const res = await instance.get("/graduates/");
      this.graduates = res.data;
      this.graduatesLoading = false;
    } catch (err) {
      console.error(err);
    }
  };

  fetchLocations = async () => {
    try {
      const res = await instance.get("/locations/");
      this.locations = res.data;
      this.locationsLoading = false;
    } catch (err) {
      console.error(err);
    }
  };

  getGraduate = slug => {
    return this.graduates.find(graduate => graduate.slug === slug);
  };

  get loading() {
    return this.locationsLoading || this.graduatesLoading;
  }
}

decorate(GraduateStore, {
  graduates: observable,
  graduate: observable,
  locations: observable,
  graduatesLoading: observable,
  locationsLoading: observable,
  loading: computed
});

const graduateStore = new GraduateStore();
export default graduateStore;
graduateStore.fetchAllGraduates();
graduateStore.fetchLocations();
