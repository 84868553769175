import React from "react";

const Card = ({ graduate }) => {
  return (
    <div
      className="card bg-dark-grey"
      style={{
        width: "353px",
        height: "500px",
        borderRadius: 12
      }}
    >
      <div className="card-body">
        <img
          alt={graduate.name}
          className="mx-auto mt-2"
          src={graduate.image}
          style={{
            width: 156,
            height: 156,
            borderRadius: 15,
            objectFit: "cover"
          }}
        />
        <h6 className="text-white mx-auto font-weight-bold mt-4">
          {graduate.name}
        </h6>
        <p className="mx-auto text-center small text-grey">{graduate.story}</p>
      </div>
      {graduate.hired_by ? (
        <div className="card-footer bg-dark-grey pb-4 border-0">
          <p className="text-purple small font-weight-bold mb-1">Hired By</p>
          <img src={graduate.hired_by.logo} alt={graduate.hired_by.name} />
        </div>
      ) : null}
    </div>
  );
};

export default Card;
