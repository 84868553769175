import React from "react";

const Capstone = ({ capstones }) => {
  const capstoneCards = capstones.map((capstone, index) => (
    <div className="card bg-dark-grey text-left mb-3" key={index}>
      <div className="card-body">
        <p className="text-white font-weight-bold">{capstone.title}</p>
        <p className="text-grey">{capstone.description}</p>
        <p className="small text-dark-grey m-0 font-weight-bold">TEAM</p>
        <p className="small text-grey">{capstone.team.join(", ")}</p>

        {capstone.video ? (
          <iframe
            className="col-lg-8 col-md-12 px-md-2 px-0"
            style={{ borderRadius: "20px" }}
            height="300px"
            src={capstone.video}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={capstone.title}
          ></iframe>
        ) : null}
      </div>
    </div>
  ));
  return (
    <div>
      <p className="text-dark-grey text-left font-weight-bold mt-4">
        CAPSTONE PROJECTS
      </p>
      {capstoneCards}
    </div>
  );
};

export default Capstone;
