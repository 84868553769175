import React from "react";

// icons
import github from "../../assets/icons/github.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import codeWars from "../../assets/icons/codeWars.svg";
import location from "../../assets/icons/location.svg";
import globe from "../../assets/icons/globe.svg";
import map from "../../assets/icons/map.svg";
import home from "../../assets/icons/home.svg";
import language from "../../assets/icons/language.svg";
import cap from "../../assets/icons/cap.svg";
import certificate from "../../assets/icons/certificate.svg";
import image from "../../assets/images/profile_placeholder.png";

// Components
import ProgrammingLanguage from "./ProgrammingLanguage";

const PortofolioWebsites = [
  {
    key: "github",
    name: "GitHub",
    icon: github
  },
  {
    key: "codewars",
    name: "Code Wars",
    icon: codeWars
  },
  {
    key: "linkedin",
    name: "LinkedIn",
    icon: linkedin
  }
];

const GraduateInfo = ({ graduate }) => {
  const programminglanguages = graduate.skills.map((skills, index) => (
    <ProgrammingLanguage language={skills} key={index} />
  ));
  const portofolioButtons = PortofolioWebsites.map(website =>
    graduate[website.key] ? (
      <a
        className="btn btn-light mt-3 text-left mx-1"
        href={graduate[website.key]}
        target="_blank"
        rel="noopener noreferrer"
        key={website.key}
      >
        <img src={website.icon} alt="github" className="my-auto" />
      </a>
    ) : null
  );
  const hideExperience = Math.round(graduate.experience * 12) === 0;
  return (
    <>
      <div className="card bg-dark-grey ">
        <div className="card-body">
          <center>
            <img
              alt={graduate.name}
              src={graduate.image ? graduate.image : image}
              style={{
                width: 140,
                height: 140,
                borderRadius: 15,
                objectFit: "cover",
                marginTop: "-60px"
              }}
            />
          </center>
          <h5 className="text-white font-weight-bold mt-4 mb-1 text-center">
            {graduate.name}
          </h5>
          {hideExperience ? null : (
            <p className="small text-grey text-center">
              {graduate.experience >= 1
                ? `${Math.round(graduate.experience)} Years of Experience`
                : `${Math.round(
                    graduate.experience * 12
                  )} Months of Experience`}
            </p>
          )}

          <p className="text-white text-center font-weight-bold">
            {graduate.titles.join(", ")}
          </p>
          <div className="d-flex flex-wrap justify-content-center">
            {programminglanguages}
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {portofolioButtons}
          </div>
        </div>
      </div>
      <div className="card bg-dark-grey mt-3">
        <div className="card-body">
          {graduate.backgrounds.length !== 0 ? (
            <p className="text-grey text-left">
              <img src={certificate} alt="certificate" />
              <span className="ml-2">{graduate.backgrounds.join(", ")}</span>
            </p>
          ) : null}

          <p className="text-grey text-left">
            <img src={location} alt="location" />
            <span className="ml-2">{graduate.location}</span>
          </p>
          {graduate.nationality ? (
            <p className="text-grey text-left">
              <img src={globe} alt="nationality" />
              <span className="ml-2">{graduate.nationality}</span>
            </p>
          ) : null}
          {graduate.relocate ? (
            <p className="text-grey text-left">
              <img src={map} alt="map" />
              <span className="ml-2">Open to relocating</span>
            </p>
          ) : null}
          {graduate.remote ? (
            <p className="text-grey text-left">
              <img src={home} alt="home" />
              <span className="ml-2">Willingness to work remotely</span>
            </p>
          ) : null}
          <p className="text-grey text-left ">
            <img src={language} alt="langugae" />
            <span className="ml-2 text-capitalize">
              {graduate.languages.join(", ")}
            </span>
          </p>
          <p className="text-grey text-left mb-0">
            <img src={cap} alt="cap" />
            <span className="ml-2 ">
              CODED Graduation: {graduate.graduation_date}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default GraduateInfo;
