import React from "react";

const ProgrammingLanguage = ({ language }) => {
  return (
    <div
      className="mb-1 mt-1 px-2 py-1 text-grey small rounded mx-1 d-inline"
      style={{ backgroundColor: "#2A2A36" }}
    >
      {language}
    </div>
  );
};

export default ProgrammingLanguage;
