import React from "react";
import { observer } from "mobx-react";

import GraduateStore from "../../store/GraduateStore";
// Components
import Form from "./Form";
import List from "./List";
import SuccessStories from "./SuccessStories";

const GraduatesList = () => {
  const graduates = GraduateStore.graduates;
  const loading = (
    <center>
      <div
        className="spinner-border text-light text-center"
        role="status"
        style={{ marginTop: "100px", width: "50px", height: "50px" }}
      >
        <span className="sr-only text-white">Loading...</span>
      </div>
      {/* <h4 className="text-white text-center mt-1">Loading</h4> */}
    </center>
  );
  const successGraduates = graduates.filter(
    graduate => graduate.successful_story
  );
  return (
    <div>
      <div
        className="jumbotron jumbotron-fluid mt-2 mx-auto"
        style={{ width: "98%", borderRadius: 8, backgroundColor: "#18181f" }}
      >
        <div className="container">
          <h4 className="display-4 text-light mt-4 font-weight-bold text-center">
            CODED Alumni
          </h4>
          {GraduateStore.loading ? (
            loading
          ) : (
            <div className="mx-auto">
              <List graduates={graduates} />
            </div>
          )}
        </div>
        {!GraduateStore.loading ? (
          successGraduates.length === 0 ? null : (
            <center>
              <SuccessStories graduates={successGraduates} />
            </center>
          )
        ) : null}

        <Form />
      </div>
    </div>
  );
};

export default observer(GraduatesList);
