import React from "react";
import ReactTypeformEmbed from "react-typeform-embed-2";

const Form = () => {
  return (
    <div
      className="jumbotron jumbotron-fluid mx-auto pt-3 mt-5"
      style={{
        backgroundColor: "#202029",
        width: "80%",
        borderRadius: 15,
      }}
      id="contact"
    >
      <div className="container mt-5">
        <h4 className="text-light" style={{ fontWeight: 400 }}>
          Contact Us
        </h4>
        <h6
          className="mt-4"
          style={{
            fontWeight: 300,
            color: "#80808a",
          }}
        >
          If you're interested in recruiting, please complete and submit the
          form below.
        </h6>
        <section
          className="text-center"
          style={{ height: 400, borderRadius: "12px" }}
        >
          <ReactTypeformEmbed
            url={"https://joincoded.typeform.com/to/wyiepAWw"}
          />
        </section>
      </div>
    </div>
  );
};

export default Form;
