import React, { Component } from "react";
import Check from "../assets/check.PNG";
import Email from "../assets/email.PNG";
import Phone from "../assets/phone.PNG";

export default class Guidelines extends Component {
  render() {
    return (
      <div className="text-center">
        <div
          className="jumbotron jumbotron-fluid mt-2 mx-auto text-light"
          style={{
            width: "98%",
            borderRadius: 8,
            backgroundColor: "#18181f"
          }}
        >
          <div className="row">
            <div
              className="col headerSmallMarginLeft"
              style={{ marginLeft: 100 }}
            >
              <h2
                className="text-light text-left mt-5 smallerFontHeader"
                style={{ fontWeight: "bold" }}
              >
                Recruitment Guidelines:
              </h2>

              <h5
                className="text-left mt-3"
                style={{
                  color: "#a6a6bf",
                  fontSize: 15.5,

                  width: "95%",
                  maxWidth: 500,
                  lineHeight: 1.5
                }}
              >
                The following rules & guidelines are to be followed by all
                parties interested in potentially hiring or interviewing the
                following CODED alumni:
              </h5>
            </div>
          </div>
          <div className="row  justify-content-between">
            <div
              className="col-xl-6 col-sm-12 col-md-6 col-lg-6 col-xs-12 smallerColMargin"
              style={{ marginLeft: 85 }}
            >
              <div
                className="jumbotron jumbotron-fluid pb-4 pt-4 mx-auto mt-4 pr-2"
                style={{
                  width: "95%",
                  borderRadius: 15,
                  backgroundColor: "#202029",
                  maxWidth: 620
                }}
              >
                <div className="row mt-2">
                  <div className="col-2 pr-0 " style={{ width: "30%" }}>
                    <img
                      alt="check"
                      src={Check}
                      style={{
                        width: 27,
                        height: 27,
                        borderRadius: 5,
                        display: "inline"
                      }}
                    />
                  </div>
                  <div className="col-10 pl-0 text-left">
                    <h5
                      style={{
                        fontSize: 15,

                        width: "95%",
                        lineHeight: 1.5
                      }}
                    >
                      All recruitment and/or interview requests must be
                      submitted directly to CODED through the Recruitment
                      Request form and{" "}
                      <span style={{ color: "#785EBB" }}>
                        not be communicated directly with the candidate(s).
                      </span>
                    </h5>
                    <h5 style={{ fontSize: 15 }}>
                      <span style={{ color: "#785EBB", fontSize: 20 }}>• </span>
                      Select the name(s) of the candidate you are interested in
                      interviewing.
                    </h5>
                    <h5 style={{ fontSize: 15 }}>
                      <span
                        style={{
                          color: "#785EBB",
                          fontSize: 20
                        }}
                      >
                        •{" "}
                      </span>
                      Recruiters can request the resumes of any potential
                      candidates.
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className="jumbotron jumbotron-fluid pb-4 pt-4 mx-auto mt-4 pr-3"
                style={{
                  width: "95%",
                  borderRadius: 15,
                  backgroundColor: "#202029",
                  maxWidth: 620
                }}
              >
                <div className="row mt-2">
                  <div className="col-2 pr-0 " style={{ width: "30%" }}>
                    <img
                      alt="check"
                      src={Check}
                      style={{
                        width: 27,
                        height: 27,
                        borderRadius: 5,
                        display: "inline"
                      }}
                    />
                  </div>
                  <div className="col-10 pl-0 text-left">
                    <h5
                      style={{
                        fontSize: 15,

                        width: "95%",
                        lineHeight: 1.5
                      }}
                    >
                      CODED will charge the recruiter a Recruitment Fee which is
                      equal to{" "}
                      <span style={{ color: "#785EBB" }}>
                        2 times the offered salary
                      </span>{" "}
                      if a full-time job offer is accepted and signed by the
                      candidate(s).
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-5 mx-auto col-lg-4 col-xs-12 ">
              <div
                className="jumbotron jumbotron-fluid mt-4 pt-0 pb-4 mx-auto text-light"
                style={{
                  width: "95%",
                  borderRadius: 15,
                  backgroundColor: "#18181f",
                  borderColor: "#202029",
                  borderStyle: "solid",
                  borderWidth: 2
                }}
              >
                <h5
                  className="text-left ml-4"
                  style={{ fontWeight: "bold", marginTop: 33 }}
                >
                  Contact Us
                </h5>
                <h5
                  className="text-left ml-4 mt-2 mr-3"
                  style={{ fontSize: 14, color: "#a6a6bf", lineHeight: 1.5 }}
                >
                  If you have any questions about the recruitment process, feel
                  free to contact us.
                </h5>
                <div className="text-left ml-4 mt-4">
                  <h5 style={{ fontSize: 14, color: "#a6a6bf" }}>
                    <span>
                      <img
                        alt="email"
                        src={Email}
                        style={{ width: 20, height: 17, marginRight: 10 }}
                      />
                    </span>
                    SUBJECT: "Recruitment Request"
                  </h5>
                  <h5 style={{ fontSize: 14, marginLeft: 30 }}>
                    careers@joincoded.com
                  </h5>
                  <hr style={{ width: "75%", borderWidth: 3 }} />
                  <h5 style={{ fontSize: 14, color: "#a6a6bf" }}>
                    <span>
                      <img
                        alt="phone"
                        src={Phone}
                        style={{ width: 13, height: 22, marginRight: 10 }}
                      />
                    </span>
                    Call or WhatsApp
                  </h5>
                  <h5 style={{ fontSize: 14, marginLeft: 23 }}>60791018</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
