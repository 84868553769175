import React, { useState, useRef } from "react";
import useOnClickOutside from "../../../Hooks/useOnClickOutside";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import arrowUp from "../../../assets/icons/arrowUp.svg";

const DropDown = ({ label, options, selected, onChange }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);
  useOnClickOutside(ref, () => setShow(false));
  const handleClicke = value => {
    setShow(false);
    onChange(label, value);
  };

  const items = options.map(option => {
    const isSelected =
      selected.toString().toLowerCase() ===
      option.value.toString().toLowerCase();
    return (
      <button
        key={option.value}
        className={isSelected ? "dd-item selected py-2" : "dd-item py-2"}
        onClick={() => {
          handleClicke(option.value);
        }}
      >
        <span className={isSelected ? "selected-dot" : "dot"}></span>
        {option.text}
      </button>
    );
  });
  return (
    <>
      <div className="dropdown d-block" ref={ref}>
        <p className="mb-0 text-white font-weight-bold small mb-1 text-uppercase">
          {label}
        </p>
        <button
          className="border-0 dd-button py-3 pl-3 pr-1 w-100 text-left"
          type="button"
          onClick={() => setShow(prevShow => !prevShow)}
        >
          <span className="text-grey pr-3 font-weight-light mr-auto d-flex justify-content-middle">
            {options.find(option => option.value === selected).text}
            <img
              src={show ? arrowUp : arrowDown}
              className="float-right ml-auto"
              alt="arrow"
            />
          </span>
        </button>
        {show ? (
          <div className="dropdown-menu show p-0 w-100">{items}</div>
        ) : null}
      </div>
    </>
  );
};

export default DropDown;
