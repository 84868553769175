import React from "react";
import Switch from "react-switch";
import DropDown from "./DropDown";
import GraduateStore from "../../../store/GraduateStore";

const Filters = ({
  query: { experience, location, remote, relocate },
  handleChange
}) => {
  const locations = GraduateStore.locations.map(location => {
    return { text: location.title, value: location.title };
  });
  return (
    <div className="row d-flex justify-content-end">
      <div className="col-md-3 col-12">
        <DropDown
          label="experience"
          selected={experience}
          options={[
            { text: "No Preference", value: "anything" },
            { text: ">6 months", value: 0.5 },
            { text: ">1 year", value: 1 },
            { text: ">2 years", value: 2 },
            { text: ">3 years", value: 3 }
          ]}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-3 col-12">
        <DropDown
          label="location"
          selected={location}
          options={[{ text: "Anywhere", value: "anything" }, ...locations]}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6 row mt-3">
        <div className="d-flex align-items-center ">
          <span className="text-white text-uppercase small font-weight-bold mr-2">
            Relocating
          </span>
          <Switch
            onChange={() => handleChange("relocate", !relocate)}
            checked={relocate}
            offColor="#3A3A50"
            offHandleColor="#666683"
            onColor="#7745EB"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={24}
            height={32}
            width={62}
          />
        </div>
        <div className="d-flex align-items-center ml-3">
          <span className="text-white text-uppercase small font-weight-bold mr-2">
            Remote
          </span>
          <Switch
            onChange={() => handleChange("remote", !remote)}
            checked={remote}
            offColor="#3A3A50"
            offHandleColor="#666683"
            onColor="#7745EB"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={24}
            height={32}
            width={62}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
