import React, { useState } from "react";
import { Link } from "react-router-dom";
import refresh from "../../../assets/icons/refresh.svg";
// Components
import Card from "./Card";
import Filter from "../Filters";

const List = ({ graduates }) => {
  const [query, setQuery] = useState({
    experience: "anything",
    location: "anything",
    relocate: false,
    remote: false
  });

  const anythingString = "anything";

  const handleChange = (name, value) => {
    setQuery({ ...query, [name]: value });
  };

  const filteredGraduates = graduates.filter(graduate => {
    if (
      query.experience.toString().toLowerCase() !== anythingString &&
      graduate.experience < query.experience
    )
      return false;

    if (
      query.location.toLowerCase() !== anythingString &&
      query.location.toLowerCase() !== graduate.location.toLowerCase()
    )
      return false;
    if (query.remote && !graduate.remote) return false;
    if (query.relocate && !graduate.relocate) return false;

    return true;
  });

  const increment = 8;
  const [numberOfShown, setNumberOfShown] = useState(8);
  const handleShowMore = () => {
    setNumberOfShown(prev => prev + increment);
  };
  const graduatesCards = filteredGraduates
    .slice(0, numberOfShown)
    .map(graduate => (
      <div className="col-12 col-xl-3 col-md-4 mt-5 mb-3" key={graduate.slug}>
        <Link to={graduate.slug}>
          <Card graduate={graduate} />
        </Link>
      </div>
    ));

  return (
    <>
      <Filter handleChange={handleChange} query={query} />
      <div className="card-deck mt-5 mx-auto">{graduatesCards}</div>
      {numberOfShown < filteredGraduates.length ? (
        <center>
          <button className="custom-btn border-0 p-3" onClick={handleShowMore}>
            <img src={refresh} className="mt-n1 mr-1" alt="refresh" />
            <span className="text-white font-weight-bold">Show more</span>
          </button>
        </center>
      ) : null}
    </>
  );
};

export default List;
