import React from "react";

const Intro = ({ graduate }) => {
  return (
    <div>
      <p className="text-dark-grey text-left font-weight-bold mt-4">
        ABOUT THE CODER
      </p>
      <div className="card bg-dark-grey text-left mb-3">
        <div className="card-body">
          <p className="text-grey">{graduate.intro}</p>
          <p className="small text-dark-grey m-0 font-weight-bold">STRENGTHS</p>
          <p className="small text-grey">{graduate.strengths.join(", ")}</p>
        </div>
      </div>
    </div>
  );
};

export default Intro;
