import React from "react";

// components
import ProgrammingLanguage from "../../GraduateDetails/ProgrammingLanguage";

// images
import hired from "../../../assets/images/hired.png";
import image from "../../../assets/images/profile_placeholder.png";

const Card = ({ graduate }) => {
  const strengths = graduate.strengths.map((strength, index) => (
    <ProgrammingLanguage language={strength} key={index} />
  ));

  return (
    <div
      className="card mb-5 bg-dark-grey px-1 pb-3 mx-auto"
      style={{
        width: 230,
        borderRadius: 12
      }}
    >
      <img
        alt={graduate.name}
        className="mx-auto"
        src={graduate.image ? graduate.image : image}
        style={{
          width: 120,
          height: 120,
          borderRadius: 15,
          objectFit: "cover",
          marginTop: "-60px"
        }}
      />
      {graduate.hired ? (
        <img
          src={hired}
          alt="hired"
          width="73px"
          height="73px"
          style={{
            marginLeft: "45px",
            marginTop: "-125px",
            marginBottom: "60px"
          }}
        />
      ) : null}

      <h6 className="text-white mx-auto font-weight-bold mt-3">
        {graduate.name}
      </h6>
      <p className="mx-auto text-center small text-grey mb-2">
        {graduate.titles.join(", ")}
      </p>
      <div className="d-flex flex-wrap justify-content-center">{strengths}</div>
    </div>
  );
};

export default Card;
