import React from "react";
import { Link } from "react-router-dom";
import CodedLogo from "../../assets/Coded-navy.svg";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar" style={{ height: 105, backgroundColor: "white" }}>
        <span className="navbar-brand mb-0 h1">
          <Link to="/">
            <img
              alt="coded logo"
              className="ml-lg-5 ml-xl-5 ml-md-5 ml-sm-1 ml-xs-1"
              src={CodedLogo}
              width={108}
              height={42}
              style={{
                filter: "brightness(0)"
              }}
            />
          </Link>
        </span>
        <span>
          <span className="smallerFont">
            <Link to="/">Home</Link>
          </span>
          {/* <span
            className="smallerFont"
            style={{ marginLeft: 15, marginRight: 15 }}
          >
            <Link to="/guidelines">Recruitment Guidelines</Link>
          </span> */}
          <span className="smallerFont" style={{ marginRight: 30,  marginLeft: 30 }}>
            <HashLink to="/#contact">Contact</HashLink>
          </span>
        </span>
      </nav>
    </div>
  );
};

export default Navbar;
