import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import { observer } from "mobx-react";
import "./App.css";

// Components
import Navbar from "./Components/Navbar/";
import GraduatesList from "./Components/GraduatesList";
import GraduateDetails from "./Components/GraduateDetails";
import Guidelines from "./Components/Guidelines";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route path="/guidelines/" component={Guidelines} />
        <Route exact path="/:slug/" component={GraduateDetails} />
        <Route exact path="/" component={GraduatesList} />
        <Redirect to="/" />
      </Switch>
      <footer>
        <p>© 2020 CODED</p>
      </footer>
    </div>
  );
}

export default withRouter(observer(App));
