import React, { Component } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import arrowSide from "../../assets/icons/arrowSide.svg";

class CustomCarousel extends Component {
  myArrow = ({ type, onClick }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowSide} className="bg-dark-grey" alt="arrow right" />
      ) : (
        <img
          src={arrowSide}
          className="bg-dark-grey"
          style={{ transform: "rotate(180deg)" }}
          alt="arrow left"
        />
      );
    return (
      <div
        onClick={onClick}
        className="bg-dark-grey p-auto d-flex justify-content-middle justify-content-center my-auto"
        style={{
          borderRadius: "50%",
          height: "54px",
          width: "54px",
          cursor: "pointer"
        }}
      >
        {pointer}
      </div>
    );
  };
  breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 2 },
    { width: 1100, itemsToShow: 3 }
  ];

  render() {
    return (
      <Carousel
        itemsToShow={3}
        renderArrow={this.myArrow}
        breakPoints={this.breakPoints}
        pagination={false}
        enableMouseSwipe
      >
        {this.props.items}
      </Carousel>
    );
  }
}

export default CustomCarousel;
