import React from "react";
import { default as Carousel } from "../../common/CustomCarousel";
import Card from "./Card";

const SuccessStories = ({ graduates }) => {
  const graduateCards = graduates.map(graduate => (
    <Card graduate={graduate} key={graduate.id} />
  ));
  return (
    <div className="col-xl-9 col-lg-10 col-12">
      <h4 className="text-light mt-4" style={{ fontSize: "40px" }}>
        Successful Alumni Hiring Story
      </h4>
      <Carousel items={graduateCards} />
    </div>
  );
};

export default SuccessStories;
