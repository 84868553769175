import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

// Store
import GraduateStore from "../../store/GraduateStore";

// components
import GraduateInfo from "./GraduateInfo";
import Capstone from "./Capstone";
import Intro from "./Intro";

const GraduateDetails = () => {
  let { slug } = useParams();
  if (GraduateStore.loading) return <p>loading</p>;
  const graduate = GraduateStore.getGraduate(slug);
  return (
    <div
      className="col-xl-10 mx-auto px-xl-0 px-4"
      style={{ marginTop: "100px" }}
    >
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <GraduateInfo graduate={graduate} />
        </div>
        <div className="col-xl-9 col-lg-8">
          <Intro graduate={graduate} />
          <Capstone capstones={graduate.capstones} />
        </div>
      </div>
    </div>
  );
};

export default observer(GraduateDetails);
